import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from 'react-toastify';

//3rd party
import moment from 'moment';
import { Watch } from  'react-loader-spinner';
import Backdrop from '@mui/material/Backdrop';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import * as Icon from 'react-bootstrap-icons';
import { getWeek } from 'date-fns';

//Functions
import { apiCall, apiError } from '../functions/common';

//Components
import Header from '../components/Header';
import BottomMenu from '../components/BottomMenu';
import DayRowOverview from '../components/DayRowOverview';
import TableRowSkeleton from '../components/TableRowSkeleton';
import ModalModify from '../components/ModalModify';
import ModalOfferHours from '../components/ModalOfferHours';
import Artwork from '../components/Artwork';
import Dropdown from '../components/Dropdown';
import ModalReviewHours from '../components/ModalReviewHours';

//Styles
import '../css/overview.css';

const Overview = () => {

    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const functie_id = localStorage.getItem('functie_id');
    if(functie_id != 4){ // Directie
        navigate('/');
    }
    let userId = localStorage.getItem('user_id');
    if(userId == null){
        userId = 0;
    }
    const code = global.settings.api.code;
    const default_period = 5; //This month = default (3)

    // States
    const [date, setDate] = useState(Date.now());
    const [showDate, setShowDate] = useState(moment().format('DD-MM-YYYY'));
    const [submitDate, setSubmitDate] = useState(moment().format('YYYY-MM-DD'));
    const [registerDate, setRegisterDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateLabel, setDateLabel] = useState(moment().format('LL'));
    const [totals, setTotals] = useState('');
    const [loadingRegistrations, setLoadingRegistrations] = useState(false);
    const [showSkeletonsRegistrations, setShowSkeletonsRegistrations] = useState(true);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [registrations, setRegistrations] = useState([]);
    const [totalHours, setTotalHours] = useState(0);
    const [checkAll, setCheckAll] = useState(false);
    const [checkedRows, setCheckedRows] = useState({});
    const [checkedIds, setCheckedIds] = useState([]);

    // Filters
    const [periods, setPeriods] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(default_period);
    const [selectedWeek, setSelectedWeek] = useState(getWeek(new Date()));
    const [showWeekDropdown, setShowWeekDropdown] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(userId);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(0);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(0);
    const [construction_numbers, setConstructionNumbers] = useState([]);
    const [selectedConstructionNumber, setSelectedConstructionNumber] = useState(0);
    const [decks, setDecks] = useState([]);
    const [selectedDeck, setSelectedDeck] = useState(0);
    const [hour_types, setHourTypes] = useState([]);
    const [selectedHourType, setSelectedHourType] = useState(0);
    const [statusses, setStatusses] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(0);

    const [order_by, setOrderBy] = useState('desc');
    const [order_by_name, setOrderByName] = useState('datum');

    const week_numbers = [...Array(52).keys()].map(i => ({
        value: i + 1,
        label: `Week ${i + 1}`,
        actief: 1
    }));

    // Modal variables
    const [showModal, setShowModal] = useState(0);
    const [modalData, setModalData] = useState([]);

    const [showModalOfferHours, setShowModalOfferHours] = useState(0);
    const [modalDataOfferHours, setModalDataOfferHours] = useState([]);

    // Get user registrations
    const getRegistrations = async (type) => {
        setLoadingRegistrations(true);
        if (type === 'skeleton') { setShowSkeletonsRegistrations(true) }
        //setTotals('');
        try {
            const response = await apiCall(`registraties/overzicht?code=${code}
                &periode_id=${selectedPeriod}
                &week_number=${selectedWeek}
                &employee_id=${selectedEmployee}
                &location_id=${selectedLocation}
                &client_id=${selectedClient}
                &construction_number_id=${selectedConstructionNumber}
                &deck_id=${selectedDeck}
                &hour_type_id=${selectedHourType}
                &status_id=${selectedStatus}
            `);
            // Success
            if (response.data.success) {
                const data = response.data.data;
                setRegistrations(data.registraties);
                setTotalHours(data.totaal_uren);
                setPeriods(data.periods);
                setEmployees(data.employees);
                setLocations(data.locations);
                setClients(data.clients);
                setConstructionNumbers(data.construction_numbers);
                setDecks(data.decks);
                setHourTypes(data.hour_types);
                setStatusses(data.statusses);

                /*if(data.registratie.totaal_uren){
                    setTotals((Math.round(data.registratie.totaal_uren * 100) / 100).toFixed(2));
                } else {
                    setTotals('');
                }*/

                setOpenBackdrop(false);
                setShowModal(false);
                setShowModalOfferHours(false);
                setShowSkeletonsRegistrations(false);
                setLoadingRegistrations(false);
            }
        } catch (error) {
            // Error
            apiError(error);
        }
    }

    /*const handleChangeDate = (value) => {
        setRegisterDate(moment(value.$d).format('YYYY-MM-DD'));
        setDateLabel(moment(value.$d).format('LL'));
        getRegistrations(moment(value.$d).format('YYYY-MM-DD'), 'skeleton');
        setSubmitDate(moment(value.$d).format('YYYY-MM-DD'));
        setShowDate(moment(value.$d).format('DD-MM-YYYY'));
    }*/

    
    const deleteRow = async (id, row_id) => {
        console.log("Deleterow");
        setShowSkeletonsRegistrations(true);
        //Call API
        try {
            const response = await apiCall(`registratie/${id}/verwijderen?code=${code}&rij_id=${row_id}`, 'post');

            // Success
            if (response.data.success) {
                getRegistrations();
                setShowSkeletonsRegistrations(false);
            }
        } catch (error) {
            // Error
            apiError(error);
        }
    }

    const handleReview = async (status_id, opmerking) => {
        //Call API
        console.log("handle review");
        setShowSkeletonsRegistrations(true);
        try {
            const response = await apiCall(`registratie/beoordelen?code=${code}&uren_ids=${JSON.stringify(checkedIds)}&status_id=${status_id}&opmerking=${opmerking}`, 'post');

            // Success
            if (response.data.success) {
                //window.location.reload();

            }
            getRegistrations();
            setShowSkeletonsRegistrations(false);
        } catch (error) {
            // Error
            apiError(error);
        }
    }

    const renderModal = (uren_ids, status_id) => {
        const obj = {
            uren_ids: uren_ids,
            status_id: status_id
        }
        setModalData(obj);
        setShowModal(showModal + 1);
        //console.log("Show modal");
    }

    const renderOfferHoursModel = (id) => {
        const obj = {
            id: id,
            submitDate: submitDate
        }
        setModalDataOfferHours(obj);
        setShowModalOfferHours(showModalOfferHours + 1);
    }

    const handleSelectAll = () => {
        const newCheckAll = !checkAll;
        setCheckAll(newCheckAll);

        const newSelectedRows = {};
        const newCheckedIds = [];
        if (newCheckAll) {
            sortedRegistrations.forEach((registration, index) => {
                if(registration.review_status && registration.afgeboekt == 1){
                    newSelectedRows[index] = true;
                    newCheckedIds.push(registration.id);
                }
            });
        }
        setCheckedRows(newSelectedRows);
        setCheckedIds(newCheckedIds);
    };

    const handleRowCheckboxChange = (index, id) => {
        setCheckedRows((prevSelectedRows) => {
            const newSelectedRows = { ...prevSelectedRows };
            if (newSelectedRows[index]) {
                delete newSelectedRows[index];
                setCheckedIds((prevIds) => prevIds.filter((checkedId) => checkedId !== id));
            } else {
                newSelectedRows[index] = true;
                setCheckedIds((prevIds) => [...prevIds, id]);
            }
            return newSelectedRows;
        });
    }

    const handlePeriodChange = (event) => {
        const period = event.target.value;
        setSelectedPeriod(period);
        //console.log("periode: " + period);
        if(period == '11'){ // Controleer of periode 11 is geselecteerd
            setShowWeekDropdown(true);
        } else {
            setShowWeekDropdown(false);
            setSelectedWeek(getWeek(new Date())); // Reset weeknummer naar de huidige week
        }
    };

    const handleEmployeeChange = (event) => {
        setSelectedEmployee(event.target.value);
    };

    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value);
    };

    const handleClientChange = (event) => {
        setSelectedClient(event.target.value);
    };

    const handleConstructionNumberChange = (event) => {
        setSelectedConstructionNumber(event.target.value);
    };

    const handleDeckChange = (event) => {
        setSelectedDeck(event.target.value);
    };

    const handleHourTypeChange = (event) => {
        setSelectedHourType(event.target.value);
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const resetFilters = () => {
        setSelectedPeriod(default_period);
        setSelectedEmployee(userId);
        setSelectedLocation(0);
        setSelectedClient(0);
        setSelectedConstructionNumber(0);
        setSelectedDeck(0);
        setSelectedHourType(0);
        setSelectedStatus(0);
    };

    const handleSort = (column_name) => {
        if(column_name !== order_by_name){
            setOrderBy('asc');
            setOrderByName(column_name);
        } else {
            setOrderBy((prevOrderBy) => (prevOrderBy === 'desc' ? 'asc' : 'desc'));
        }
    };

    useEffect(() => {
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        setInterval(() => setDate(new Date()), 30000);
        getRegistrations();
    }, [selectedPeriod, selectedWeek, selectedEmployee, selectedLocation, selectedClient, selectedConstructionNumber, selectedDeck, selectedHourType, selectedStatus]);

    const getCaretIcon = (columnName) => {
        if(order_by_name === columnName){
            return order_by === 'asc' ? <Icon.CaretUpFill /> : <Icon.CaretDownFill />;
        }
        return null;
    };

    const isFilterSelected = () => {
        return (
            selectedPeriod !== default_period ||
            selectedEmployee !== userId ||
            selectedLocation !== 0 ||
            selectedClient !== 0 ||
            selectedConstructionNumber !== 0 ||
            selectedDeck !== 0 ||
            selectedHourType !== 0 ||
            selectedStatus !== 0
        );
    };

    const sortedRegistrations = [...registrations].sort((a, b) => {
        let columnA = '';
        let columnB = '';
        if(order_by_name === 'datum' || order_by_name === 'medewerker'){
            columnA = a[order_by_name];
            columnB = b[order_by_name];
        } else if(order_by_name === 'aantal_uren'){
            columnA = parseFloat(a[order_by_name]?.value) || 0;
            columnB = parseFloat(b[order_by_name]?.value) || 0;
        } else if(order_by_name === 'uren_status'){
            columnA = a[order_by_name]?.naam.toLowerCase() || '';
            columnB = b[order_by_name]?.naam.toLowerCase() || '';
        } else if(order_by_name === 'opmerking'){
            columnA = a[order_by_name]?.value?.toLowerCase() || '';
            columnB = b[order_by_name]?.value?.toLowerCase() || '';
        }  else {
            columnA = a[order_by_name]?.label.toLowerCase() || '';
            columnB = b[order_by_name]?.label.toLowerCase() || '';
        }

        if (order_by === 'asc') {
            if (columnA > columnB) return 1;
            if (columnA < columnB) return -1;
            return 0;
        } else {
            if (columnA < columnB) return 1;
            if (columnA > columnB) return -1;
            return 0;
        }
    });

    return(
        <div>
            <Header />
            <div className="main">
                <div className="container">
                    <div className="content">
                        <div className="filters">
                            {/* Filters*/}
                            <div className="dropdown-container">
                                <Dropdown placeholder={"Select a period"} value={selectedPeriod} label="Which period?" onChange={handlePeriodChange} source={periods} />
                            </div>
                            {/* Weeknummer dropdown, alleen zichtbaar als periode 11 is geselecteerd */}
                            {showWeekDropdown && (
                                <div className="dropdown-container">
                                    <Dropdown 
                                        placeholder={"Select a week number"} 
                                        value={selectedWeek} 
                                        label="Which week?" 
                                        onChange={(event) => setSelectedWeek(event.target.value)} 
                                        source={week_numbers} 
                                    />
                                </div>
                            )}
                            <div className="dropdown-container">
                                <Dropdown placeholder={"Select a employee"} value={selectedEmployee} label="Which employee?" onChange={handleEmployeeChange} source={employees} />
                            </div>
                            <div className="dropdown-container">
                                <Dropdown placeholder={"Select a location"} value={selectedLocation} label="Which location?" onChange={handleLocationChange} source={locations} />
                            </div>
                            <div className="dropdown-container">
                                <Dropdown placeholder={"Select a client"} value={selectedClient} label="Which client?" onChange={handleClientChange} source={clients} />
                            </div>
                            <div className="dropdown-container">
                                <Dropdown placeholder={"Select a construction number"} value={selectedConstructionNumber} label="Which construction number?" onChange={handleConstructionNumberChange} source={construction_numbers} />
                            </div>
                            <div className="dropdown-container">
                                <Dropdown placeholder={"Select a deck"} value={selectedDeck} label="Which deck?" onChange={handleDeckChange} source={decks} />
                            </div>
                            <div className="dropdown-container">
                                <Dropdown placeholder={"Select a hour type"} value={selectedHourType} label="Which hour type?" onChange={handleHourTypeChange} source={hour_types} internExtern={true} />
                            </div>
                            <div className="dropdown-container">
                                <Dropdown placeholder={"Select a status"} value={selectedStatus} label="Which status?" onChange={handleStatusChange} source={statusses} />
                            </div>

                            {/* Wis filters */}
                            {isFilterSelected() && (
                                <div className="reset-filters-container">
                                    <a onClick={resetFilters} className="reset-filters-button">
                                        Wis filters
                                    </a>
                                </div>
                            )}
                        </div>
                        {/*loadingRegistrations ?
                            <Watch
                                height="20"
                                width="20"
                                radius="48"
                                color="#13A3F6"
                                ariaLabel="watch-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        : null*/}
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="th-checkbox">
                                        <input type='checkbox' checked={checkAll} onChange={handleSelectAll} />
                                    </th>
                                    <th className="width-50" onClick={() => handleSort('datum')}>
                                        <span className="th-content">Date {getCaretIcon('datum')}</span>
                                    </th>
                                    <th onClick={() => handleSort('medewerker')}>
                                        <span className="th-content">Employee {getCaretIcon('medewerker')}</span>
                                    </th>
                                    <th onClick={() => handleSort('locaties')}>
                                        <span className="th-content">Location {getCaretIcon('locaties')}</span>
                                    </th>
                                    <th onClick={() => handleSort('klanten')}>
                                        <span className="th-content">Client {getCaretIcon('klanten')}</span>
                                    </th>
                                    <th onClick={() => handleSort('bouwnummers')}>
                                        <Tooltip title="Construction number" className="tooltip-th" arrow>
                                            <span className="th-content">
                                                Cn {getCaretIcon('bouwnummers')}
                                            </span>
                                        </Tooltip>
                                    </th>
                                    <th onClick={() => handleSort('dekken')}>
                                        <span className="th-content">Deck {getCaretIcon('dekken')}</span>
                                    </th>
                                    <th onClick={() => handleSort('uren_soorten')}>
                                        <span className="th-content">Hour type {getCaretIcon('uren_soorten')}</span>
                                    </th>
                                    <th onClick={() => handleSort('opmerking')}>
                                        <span className="th-content">Notes {getCaretIcon('opmerking')}</span>
                                    </th>
                                    <th onClick={() => handleSort('aantal_uren')}>
                                        <span className="th-content">Hours {getCaretIcon('aantal_uren')}</span>
                                    </th>
                                    <th onClick={() => handleSort('uren_status')}>
                                        <span className="th-content">Status {getCaretIcon('uren_status')}</span>
                                    </th>
                                    <th onClick={() => handleSort('review_status')}>
                                        <span className="th-content">My review {getCaretIcon('review_status')}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {showSkeletonsRegistrations ? (
                                    <>
                                        <TableRowSkeleton/>
                                        <TableRowSkeleton/>
                                        <TableRowSkeleton/>
                                    </>
                                ) : sortedRegistrations && sortedRegistrations.length > 0 ? (
                                    <>
                                        {sortedRegistrations.map((registration, index) => (
                                            <DayRowOverview 
                                                key={index}
                                                option={registration}
                                                registration={registration}
                                                showDate={showDate}
                                                deleteRow={deleteRow}
                                                renderModal={renderModal}
                                                isChecked={checkedRows[index] || false}
                                                onCheckboxChange={() => handleRowCheckboxChange(index, registration.id)}
                                            />

                                        ))}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{totalHours}</td>
                                            <td></td>
                                        </tr>
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan="2" className="no-registrations-overview">No results</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <Artwork />
                    </div>
                    <div className={`fixed-bottom-right ${checkedIds.length > 0 ? 'show-buttons' : ''}`}>
                        <button className="approved" onClick={() => handleReview(2)}>Approve</button>
                        <button className="rejected" onClick={() => renderModal(checkedIds, 3)}>Reject</button>
                    </div>
                </div>
            </div>

            {/* Component bottom menu */}
            <BottomMenu />

            {/* Backdrop */}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
                <Watch
                    height="80"
                    width="80"
                    radius="48"
                    color="white"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </Backdrop>

            {/* Modal to modify registration line */}
            {showModal > 0 ?
                <ModalReviewHours show={showModal} data={modalData} getRegistrations={getRegistrations} />
            :
                null
            }

            {/* Modal to offer hours for the day */}
            {/*
                showModalOfferHours > 0 ?
                <ModalOfferHours show={showModalOfferHours} data={modalDataOfferHours} getRegistrations={getRegistrations} submitDate={submitDate} />
                :
                null
            */}
            <ToastContainer transition={Slide}/>
        </div>
    );
}

export default Overview;