import React, { useState, useEffect } from 'react';

//3rd party
import moment from 'moment';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from  'react-loader-spinner';

//Functions
import { apiCall, apiError } from '../functions/common';

//Components
import Datepicker from './Datepicker';
import Dropdown from './Dropdown';
import DropdownGhost from './DropdownGhost';
import Button from './Button';

//Styles
import '../css/timeform.css';

const OfferHoursForm = (props) => {

    //Props
    const registration_id = props.data.id;
    const submitDate = props.data.submitDate;
    const getRegistrations = props.getRegistrations;

    const code = global.settings.api.code;

    //Variables
    const [data, setData] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [opmerking, setOpmerking] = useState('');
    const [stayAndFoodOptions, setStayAndFoodOptions] = useState([
        {
            value: 1,
            label: 'Geen',
            actief: 1,
        },
        {
            value: 2,
            label: 'Nacht',
            actief: 1,
        },
        {
            value: 3,
            label: 'Eten/nacht',
            actief: 1,
        }
    ]);
    const [stayAndFood, setStayAndFood] = useState(1);

    //Functions
    const handleChangeStayAndFood = (event) => {
        setStayAndFood(event.target.value);
    }

    //Submit day
    const submitForm = async () => {
        try {
            const response = await apiCall(`dag/${registration_id}/afboeken?code=${code}&overnachting_eten_id=${stayAndFood}`, 'post');

            // Success
            if (response.data.success) {
                getRegistrations(submitDate);
            }
        } catch (error) {
            // Error
            apiError(error);
        }
    }


    return(
        <div className="default-form">
            <h2>Offer hours</h2>
            {/* Stay and food field */}
            <div className="form-field">
                {
                    <Dropdown placeholder="Food/accommodation" label="Food/accommodation" value={stayAndFood} onChange={handleChangeStayAndFood} source={stayAndFoodOptions} />
                }
            </div>


            {/* Submit form */}
            <div className="registration-submit">
                {
                    isUpdating ?
                        <TailSpin
                            height="30"
                            width="30"
                            color="#13a3f6"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            wrapperClass=""
                            visible={true}
                        />
                    :

                    <Button
                        text={"Send"}
                        onClick={() => submitForm()}
                    />
                }
                
            </div>
        </div>
    );
}

export default OfferHoursForm;