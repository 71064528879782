import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, Slide } from 'react-toastify';

//3rd party
import { Watch } from  'react-loader-spinner';
import Backdrop from '@mui/material/Backdrop';

//Functions
import { apiCall, apiError } from '../functions/common';

//Components
import Header from '../components/Header';
import BottomMenu from '../components/BottomMenu';
import RegisterTimeForm from '../components/RegisterTimeForm';
import DayRow from '../components/DayRow';
import DayRowSkeleton from '../components/DayRowSkeleton';
import ModalModify from '../components/ModalModify';
import ModalOfferHours from '../components/ModalOfferHours';
import Artwork from '../components/Artwork';
import ReactCalendar from '../components/ReactCalendar';


const Calendar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const code = global.settings.api.code;

    let functie_id = 0;//location?.state?.functie_id;
    console.log(location?.state?.functie_id);

    useEffect(() => {
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        //setInterval(() => setDate(new Date()), 30000);
        getCalendar(functie_id);
    }, []);

    //Variables
    const [openBackdrop, setOpenBackdrop] = useState(false);

    //Modal variables
    const [showModal, setShowModal] = useState(0);
    const [modalData, setModalData] = useState([]);

    const [datums, setDatums] = useState(false);
    console.log(datums);

    //Get datums
    const getCalendar = async (functie_id = 0) => {
        //setTotals('');
        try {
            const response = await apiCall(`kalender?code=${code}&functie_id=${functie_id}`);
            // Success
            if (response.data.success) {
                const data = response.data.data;
                setDatums(data.datums);


                setOpenBackdrop(false);
                setShowModal(false);
            }
        } catch (error) {
            // Error
            apiError(error);
        }
    }


    return(
        <div>
            <Header />
            <div className="main">
                <div className="container">
                    <div className="content">
                        <ReactCalendar
                            datums={datums}
                         />
                    </div>
                </div>
            </div>

            {/* Component bottom menu */}
            <BottomMenu />

            {/* Backdrop */}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
                <Watch
                    height="80"
                    width="80"
                    radius="48"
                    color="white"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </Backdrop>

            {/* Modal to modify registration line */}
            {
                showModal > 0 ?
                <ModalModify show={showModal} data={modalData} />
                :
                null
            }

            <ToastContainer transition={Slide}/>
        </div>
    );
}

export default Calendar;