import React from 'react';

//3rd party
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';

//Style
import '../css/dayrow.css';

const TableRowSkeleton = () => {

    return (
        <tr className="loader">
            <td colSpan="11"><Skeleton height={25} width={'100%'} /></td>
        </tr>
    );
}

export default TableRowSkeleton;