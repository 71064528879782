import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

const ReactCalendar = (props) => {
    const datums = props.datums;

    const handleDateClick = (e) => { // bind with an arrow function
      console.log(e.event.title);
    }

    console.log(datums);

    return (
      <FullCalendar
        plugins={[ dayGridPlugin ]}
        locale={'nl'}
        weekNumbers={true}
        firstDay={1}
        initialView="dayGridMonth"
        events={ datums }
        eventClick={(e) => handleDateClick(e)}
      />
    )
}

export default ReactCalendar;