import React, { useState, useEffect } from 'react';

//3rd party
import moment from 'moment';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from  'react-loader-spinner';

//Functions
import { apiCall, apiError, notification } from '../functions/common';

//Components
import Datepicker from './Datepicker';
import Dropdown from './Dropdown';
import DropdownGhost from './DropdownGhost';
import Button from './Button';

//Styles
import '../css/timeform.css';
//import { notification } from 'antd';

const RegisterTimeForm = (props) => {

    //Props
    const registerDate = props.registerDate;
    const changeDate = props.changeDate;
    const submitDate = props.submitDate;
    const getRegistrations = props.getRegistrations;
    const setOpenBackdrop = props.setOpenBackdrop;
    const afgeboekt = props.afgeboekt;
    const dataToEdit = props.dataToEdit;

    const loggedIn = localStorage.getItem('ingelogd');
    const code = global.settings.api.code;
    useEffect(() => {
        if (loggedIn === 'true') { 
            getOptions(); 
        }
    }, []);

    //Variables
    const [data, setData] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [allFormOptions, setAllFormOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [location, setLocation] = useState(dataToEdit ? dataToEdit.location : '');
    const [edit, setEdit] = useState(dataToEdit ? true : false);
    const [clientOptions, setClientOptions] = useState([]);
    const [client, setClient] = useState(dataToEdit ? dataToEdit.client : '');
    const [buildingNumbersOptions, setBuildingNumbersOptions] = useState([]);
    const [buildingNumber, setBuildingNumber] = useState(dataToEdit ? dataToEdit.buildingnumber : '');
    const [dekOptions, setDekOptions] = useState([]);
    const [dekOption, setDekOption] = useState(dataToEdit ? dataToEdit.dek : '');
    const [hourTypeOptions, setHourTypeOptions] = useState([]);
    const [hourType, setHourType] = useState(dataToEdit ? dataToEdit.hourtype : '');
    const [note, setNote] = useState(dataToEdit ? dataToEdit.opmerking : '');
    const [workedHours, setWorkedHours] = useState(dataToEdit ? dataToEdit.aantal_uren : '');
    const [newRegistrationObject, setNewRegistrationObject] = useState(
    dataToEdit ? 
    {klant:Number(dataToEdit.client),locatie:Number(dataToEdit.location),bouwnummer:Number(dataToEdit.buildingnumber),dek:Number(dataToEdit.dek),uren_soort:Number(dataToEdit.hourtype),aantal_uren:dataToEdit.aantal_uren,opmerking:dataToEdit.opmerking} 
    : 
    {klant:0,locatie:0,bouwnummer:0,dek:0,uren_soort:0,aantal_uren:0,opmerking:null}
    );

    //Fill array with options
    const setDropdownOptions = (data) => {
        var array = [];
        data.map((option, index) => {
            const obj = {
                value: option.value,
                label: option.label,
                actief: option.actief,
                extern: option.extern
            }
            array.push(obj);
        })
        return array;
    }

    const returnModifiedArray = (data) => {
        const obj = {
            value: data.value,
            label: data.label,
            actief: data.actief,
            extern: data.extern
        }
        return obj;
    }

    const setDropdownOptionsNew = (locations) => {
        var arrayLocations = [];
        var arrayClients = [];
        var arrayBuildingNumbers = [];
        var arrayDekken = [];
        var arrayUrensoorten = [];
        locations.map((location, index) => {
            //console.log(location);
            arrayLocations.push(returnModifiedArray(location));

            location.klanten.map((client, index) => {
                //console.log(client);
                arrayClients.push(returnModifiedArray(client));

                if(client.bouwnummers){
                    client.bouwnummers.map((bouwnummer, index) => {
                        arrayBuildingNumbers.push(returnModifiedArray(bouwnummer));
                    })
                } else {
                    client.bouwnummers = [];
                }
                if(client.dekken){
                    client.dekken.map((dek, index) => {
                        arrayDekken.push(returnModifiedArray(dek));
                    })
                } else {
                    client.dekken = [];
                }
                /*if(client.uren_soorten){
                    client.uren_soorten.map((urensoort, index) => {
                        arrayUrensoorten.push(returnModifiedArray(urensoort));
                    })
                } else {
                    client.uren_soorten = [];
                }*/
            })

            if(location.uren_soorten){
                location.uren_soorten.map((urensoort, index) => {
                    arrayUrensoorten.push(returnModifiedArray(urensoort));
                })
            } else {
                location.uren_soorten = [];
            }
        })
        setLocationOptions(arrayLocations);
        setClientOptions(arrayClients);
        setBuildingNumbersOptions(arrayBuildingNumbers);
        setDekOptions(arrayDekken);
        setHourTypeOptions(arrayUrensoorten);
    }

    //Get field data via API
    const getOptions = async () => {
        try {
            const response = await apiCall(`locaties?code=${code}`);
            //console.log(response.data);

            // Success
            if (response.data.success) {
                const data = response.data.data;
                const locations = data.locaties;
                setData(locations);
                setDropdownOptionsNew(locations);
                if(dataToEdit){
                    handleLocationChange(dataToEdit.location, locations);
                    handleClientChange(dataToEdit.client, locations);
                }
            }
          } catch (error) {
            // Error
            if (error.response) {
              if (error.response.status === 401) {}
              if (error.response.status === 403) {}
              if (error.response.status === 429) {}
              if (error.response.status === 500) {}
            }
          }
    }

    const handleDateChange = (value) => {
        changeDate(value);
    }

    //Empty values because parent options has changed
    const emptyValues = (types) => {
        types.map((option, index) => {
            if (option === 'location') { setLocation('') }
            if (option === 'client') { setClient('') }
            if (option === 'buildingnumber') { setBuildingNumber('') }
            if (option === 'dek') { setDekOption('') }
            if (option === 'hourtype') { setHourType('') }
            if (option === 'note') { setNote('') }
            if (option === 'hours') { setWorkedHours('') }
            if (option === 'all') { setNewRegistrationObject({klant:0,locatie:0,bouwnummer:0,dek:0,uren_soort:0,aantal_uren:0,opmerking:0}); } 
        })
    }

    const handleLocationChange = (selectedOption, locations) => {
        // Set location state
        setLocation(selectedOption);

        // Initialize clients and hourtypes arrays
        let clients = [];
        let hourtypes = [];

        // Iterate through data to find matching option
        locations.forEach((option) => {
            if (selectedOption == option.value) {
                clients = option.klanten;
                hourtypes = option.uren_soorten;
            }
        });

        // Clear values
        if(!edit){
            emptyValues(['client','buildingnumber','dek']);
        }

        // Update client and hour type options
        setClientOptions(setDropdownOptions(clients));
        setHourTypeOptions(setDropdownOptions(hourtypes));

        // Create new object and assign
        if(!edit){
            const obj = { ...newRegistrationObject }; // Copy existing object
            obj.locatie = selectedOption;
            obj.klant = 0;
            obj.bouwnummer = 0;
            obj.dek = 0;
            obj.uren_soort = 0;
            setNewRegistrationObject(obj);
        }
    }

    // Original handleChangeLocation function
    const handleChangeLocation = (event) => {
        handleLocationChange(event.target.value, data);
    }

    const handleClientChange = (selectedOption, locations) => {
        //console.log("vernader client met client: " + selectedOption);
        // Set client state
        setClient(selectedOption);

        // Initialize buildingNumbers and dekken arrays
        let buildingNumbers = [];
        let dekken = [];

        // Iterate through locations to find matching option
        locations.forEach((option) => {
            if (location == option.value) {
                option.klanten.forEach((clientOption) => {
                    if (selectedOption == clientOption.value) {
                        buildingNumbers = clientOption.bouwnummers;
                        dekken = clientOption.dekken;
                        //hourtypes = clientOption.uren_soorten;
                    }
                });
            }
        });

        // Clear values
        if(!edit){
            emptyValues(['buildingnumber','dek']);
        }

        // Update building numbers and dekken options
        setBuildingNumbersOptions(setDropdownOptions(buildingNumbers));
        setDekOptions(setDropdownOptions(dekken));

        // Create new object and assign
        if(!edit){
            const obj = { ...newRegistrationObject }; // Copy existing object
            obj.klant = selectedOption;
            obj.bouwnummer = 0;
            obj.dek = 0;
            obj.uren_soort = 0;
            setNewRegistrationObject(obj);
        }
    }

    // Original handleChangeClient function
    const handleChangeClient = (event) => {
        const selectedOption = event.target.value;
        handleClientChange(selectedOption, data);
    }

    const handleChangeBuildingNumber = (event) => {
        setBuildingNumber(event.target.value);
        //Create new object and assign
        var obj = newRegistrationObject;
        obj.bouwnummer = event.target.value;
        setNewRegistrationObject(obj);
    }

    const handleChangeDek = (event) => {
        setDekOption(event.target.value);
        //Create new object and assign
        var obj = newRegistrationObject;
        obj.dek = event.target.value;
        setNewRegistrationObject(obj);
    }

    const handleChangeHourType = (event) => {
        setHourType(event.target.value);
        //Create new object and assign
        var obj = newRegistrationObject;
        obj.uren_soort = event.target.value;
        setNewRegistrationObject(obj);
    }

    const handleChangeNote = (event) => {
        setNote(event.target.value);
        //Create new object and assign
        var obj = newRegistrationObject;
        obj.opmerking = event.target.value;
        setNewRegistrationObject(obj);
    }

    const handleWorkedHours = (event) => {
        var number = (Math.round(event.target.value * 4) / 4).toFixed(2);
        setWorkedHours(number);
        //Create new object and assign
        var obj = newRegistrationObject;
        obj.aantal_uren = number;
        setNewRegistrationObject(obj);
    }

    //Submit registration
    const submitRegistration = async () => {
        //Validate fields
        if (!newRegistrationObject.locatie) { notification('error', 'Select a location'); return; }
        if (!newRegistrationObject.klant) { notification('error', 'Select a client'); return; }
        if (!newRegistrationObject.bouwnummer) { notification('error', 'Select a construction number'); return; }
        if (!newRegistrationObject.dek) { notification('error', 'Select a deck'); return; }
        if (!newRegistrationObject.uren_soort) { notification('error', 'Select an hour type'); return; }
        if (!submitDate) { notification('error', 'Select a date'); return; }
        if (!workedHours || workedHours === '0.00') { notification('error', 'Fill in the worked hours'); return; }
        if (afgeboekt) { notification('error', 'This day is already submitted'); return; }

        if (dataToEdit) {
            setIsUpdating(true);
        }

        var dataObj = JSON.stringify(newRegistrationObject);
        if (!dataToEdit) { setOpenBackdrop(true) }

        //Call API
        try {
            let url = `registratie?code=${code}&datum=${submitDate}&rij=${dataObj}`;
            if(dataToEdit){
                url = url + `&rij_id=${dataToEdit.row_id}`;
            }
            const response = await apiCall(url, 'post');

            // Success
            if (response.data.success) {
                getRegistrations(submitDate);
                if(newRegistrationObject.locatie == 11){//Werkplaats Gemini, checken of dit ook zo is op production
                    //legen
                    console.log("legen");
                    emptyValues(['client','buildingnumber','dek','hourtype','note','hours'])
                }
            } else {
                notification('error', response.error);
            }
        } catch (error) {
            // Error
            apiError(error);
        }
    }

    //console.log(locationOptions);

    return(
        <div className="default-form">

            {/* Date field */}
            <div className="form-field date">
                {
                    dataToEdit ?
                    <DropdownGhost label={dataToEdit.showDate} />
                    :
                    <Datepicker date={registerDate} changeDate={handleDateChange} />
                }
            </div>

            {/* Location field */}
            <div className="form-field">
                { locationOptions.length > 0 ? 
                <Dropdown placeholder="Which location?" value={location} label="Which location?" onChange={handleChangeLocation} source={locationOptions} modify={dataToEdit ? true : false}/> 
                :
                <Skeleton height={40} /> }
            </div>

            {/* Client field */}
            <div className="form-field">
                {locationOptions.length > 0 && location ?
                    <Dropdown placeholder={!location ? "Select a location first" : "Which client?"} value={client} label="Which client?" onChange={handleChangeClient} source={clientOptions} modify={dataToEdit ? true : false} />
                    :
                    <div>
                        {
                            locationOptions.length === 0 ?
                                <Skeleton height={40} />
                                :
                                <DropdownGhost label="Select a location first" />
                        }
                    </div>
                }
            </div>

            {/* Construction number field */}
            <div className="form-field">
                {
                    locationOptions.length > 0 && client ?
                        <Dropdown placeholder={!client ? "Select a client first" : "Which construction number?"} value={buildingNumber} label="Which construction number?" onChange={handleChangeBuildingNumber} source={buildingNumbersOptions} modify={dataToEdit ? true : false} />
                        :
                        <div>
                            {
                                locationOptions.length === 0 ?
                                    <Skeleton height={40} />
                                    :
                                    <DropdownGhost label="Select a client first" />
                            }
                        </div>
                }
            </div>

            {/* Dek field */}
            <div className="form-field">
                {
                    locationOptions.length > 0 && client ?
                        <Dropdown placeholder={!client ? "Select a client first" : "Which deck?"} value={dekOption} label="Which deck?" onChange={handleChangeDek} source={dekOptions} modify={dataToEdit ? true : false} />
                        :
                        <div>
                            {
                                locationOptions.length === 0 ?
                                    <Skeleton height={40} />
                                    :
                                    <DropdownGhost label="Select a client first" />
                            }
                        </div>
                }
            </div>

            {/* Hourtype field */}
            <div className="form-field">
                {
                    locationOptions.length > 0 && client ?
                        <Dropdown placeholder={!client ? "Select a client first" : "Hour type"} value={hourType} label="Hour type" onChange={handleChangeHourType} source={hourTypeOptions} internExtern={true} />
                        :
                        <div>
                            {
                                locationOptions.length === 0 ?
                                    <Skeleton height={40} />
                                    :
                                    <DropdownGhost label="Select a client first" />
                            }
                        </div>

                }
            </div>

            {/* Optional notes field */}
            <div className="form-field">
                {
                    locationOptions.length > 0 ?
                        <textarea value={note} onChange={handleChangeNote} placeholder="Optional notes, example: own transport" />
                        :
                        <Skeleton height={40} />
                }
            </div>

            {/* Submit form */}
            <div className="registration-submit">
                <input type="number" value={workedHours} onBlur={handleWorkedHours} onChange={(e) => setWorkedHours(e.target.value)} placeholder="00:00" step="0.25" />
                {
                    isUpdating ?
                        <TailSpin
                            height="30"
                            width="30"
                            color="#13a3f6"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            wrapperClass=""
                            visible={true}
                        />
                    :
                    <Button
                        text={dataToEdit ? "Save" : "Add hours"}
                        onClick={() => submitRegistration()}
                        style={{width: "50%", marginBottom: 0}}
                    />
                }
                
            </div>
            {
                !dataToEdit ?
                <button onClick={() => emptyValues(['location','client','buildingnumber','dek','hourtype','note','hours','all'])} className="reset">Reset</button>
                :
                null
            }
        </div>
    );
}

export default RegisterTimeForm;