import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';

//Functions
import { apiCall, apiError } from '../functions/common';

//3rd party
import { TailSpin } from  'react-loader-spinner';

//Styles
import '../css/style.css';
import '../css/login.css';

const LostPassword = () => {

    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const code = global.settings.api.code;

    useEffect(() => {
        if (loggedIn === 'true') { navigate('/') }
    }, []);
    
    //Variables
    const [email, setEmail] = useState('');
    const [mailSent, setMailSent] = useState(false);
    const [loading, setLoading]  = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    //Lost password function
    const submitLostPassword = async () => {
        setLoading(true);
        setErrorMessage('');
        if (!email) { setErrorMessage('Vul een e-mailadres in.'); setLoading(false); return; }
        try {
            const response = await apiCall(`wachtwoord/vergeten?code=${code}&email=${email}`, 'post');

            // Success
            if (response.data.success) {
              setSuccessMessage(response.data.message);
            }
        } catch (error) {
            // Error
            apiError(error);
        }
        setLoading(false);
    }

    if (loggedIn !== 'true') {
        return(
            <div className="login">
                <div className="login-form">
                    <img src={require('../assets/images/logo.png')} alt="Gemini Teak" />
                    <div className="form">

                        {
                            !successMessage ?
                            <input
                                className="form-field"
                                type="text" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="E-mail"
                            />
                            :
                            <p className="success">{successMessage}</p>
                        }
                        
                        
                        {
                            !successMessage ?
                            <button className="submit-login" onClick={submitLostPassword}>
                            {
                                !loading ?
                                <span>Verstuur e-mail</span>
                                :
                                <TailSpin
                                height="20"
                                width="20"
                                color="white"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                wrapperClass=""
                                visible={true}
                            />
                            }
                            </button>
                            :
                            null
                        }
                        
                        <p className="form-link" onClick={() => navigate("/login")}>Terug naar inloggen</p>

                        {
                            errorMessage ?
                            <p className="error">{errorMessage}</p>
                            :
                            null
                        }

                    </div>
                </div>
                <div className="image"></div>
                <ToastContainer transition={Slide}/>
           </div>
        );
    }
}

export default LostPassword;