import React, { useState, useEffect } from 'react';

//3rd party
import moment from 'moment';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from  'react-loader-spinner';

//Functions
import { apiCall, apiError } from '../functions/common';

//Components
import Datepicker from './Datepicker';
import Dropdown from './Dropdown';
import DropdownGhost from './DropdownGhost';
import Button from './Button';

//Styles
import '../css/timeform.css';

const ReviewRequestForm = (props) => {

    //Props
    const leaveRequestId = props.dataToEdit.id;
    const statusId = props.dataToEdit.status_id;
    const beoordeelId = props.dataToEdit.beoordeel_id;
    const setOpenBackdrop = props.setOpenBackdrop;
    const getLeaveRequests = props.getLeaveRequests;

    const code = global.settings.api.code;

    //Variables
    const [data, setData] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [opmerking, setOpmerking] = useState('');

    //Functions

    //Submit verlofaanvraag afwijzen
    const submitForm = async () => {
        //Call API
        try {
            const response = await apiCall(`verlofaanvraag/${leaveRequestId}/beoordelen?code=${code}&beoordeel_id=${beoordeelId}&status_id=${statusId}&opmerking=${opmerking}`, 'post');

            // Success
            if (response.data.success) {
                //getRegistrations(submitDate);
                getLeaveRequests();
            }
        } catch (error) {
            // Error
            apiError(error);
        }
    }


    return(
        <div className="default-form">
            <h2>Review leave request</h2>
            {/* Optional notes field */}
            <div className="form-field">
                 <textarea value={opmerking} onChange={(e) => setOpmerking(e.target.value)} placeholder="Write a comment..." />
            </div>

            {/* Submit form */}
            <div className="registration-submit">
                {
                    isUpdating ?
                        <TailSpin
                            height="30"
                            width="30"
                            color="#13a3f6"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            wrapperClass=""
                            visible={true}
                        />
                    :

                    <Button
                        text={"Send"}
                        onClick={() => submitForm()}
                    />
                }
                
            </div>
        </div>
    );
}

export default ReviewRequestForm;