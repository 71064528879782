import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from 'react-toastify';

//3rd party
import Moment from 'react-moment';
import moment from 'moment';
import * as Icon from 'react-bootstrap-icons';
import { TailSpin, Watch } from  'react-loader-spinner';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

//Functions
import { apiCall, apiError } from '../functions/common';

//Components
import Header from '../components/Header';
import BottomMenu from '../components/BottomMenu';
import Greeting from '../components/Greeting';
import RegisterTimeForm from '../components/RegisterTimeForm';
import DayRow from '../components/DayRow';
import DayRowSkeleton from '../components/DayRowSkeleton';
import ModalModify from '../components/ModalModify';
import ModalOfferHours from '../components/ModalOfferHours';
import Artwork from '../components/Artwork';

const Home = () => {

    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const code = global.settings.api.code;

    useEffect(() => {
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        setInterval(() => setDate(new Date()), 30000);
        getRegistrations(moment().format('YYYY-MM-DD'));
    }, []);

    //Variables
    const [date, setDate] = useState(Date.now());
    const [showDate, setShowDate] = useState(moment().format('DD-MM-YYYY'));
    const [submitDate, setSubmitDate] = useState(moment().format('YYYY-MM-DD'));
    const [registerDate, setRegisterDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateLabel, setDateLabel] = useState(moment().format('LL'));
    const [registrationObject, setRegistrationObject] = useState(null);
    const [totals, setTotals] = useState('');
    const [loadingRegistrations, setLoadingRegistrations] = useState(false);
    const [showSkeletonsRegistrations, setShowSkeletonsRegistrations] = useState(true);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [afgeboekt, setAfgeboekt] = useState(false);

    //Modal variables
    const [showModal, setShowModal] = useState(0);
    const [modalData, setModalData] = useState([]);

    const [showModalOfferHours, setShowModalOfferHours] = useState(0);
    const [modalDataOfferHours, setModalDataOfferHours] = useState([]);

    //Get user registrations
    const getRegistrations = async (date, type) => {
        setLoadingRegistrations(true);
        if (type === 'skeleton') { setShowSkeletonsRegistrations(true) }
        //setTotals('');
        try {
            const response = await apiCall(`registraties?code=${code}&datum=${date}`);
            // Success
            if (response.data.success) {
                const data = response.data.data;
                setRegistrationObject(data.registratie);

                if(data.registratie.totaal_uren){
                    setTotals((Math.round(data.registratie.totaal_uren * 100) / 100).toFixed(2));
                } else {
                    setTotals('');
                }

                if(data.registratie.afgeboekt){
                    setAfgeboekt(data.registratie.afgeboekt);
                } else {
                    setAfgeboekt(false);
                }
                setOpenBackdrop(false);
                setShowModal(false);
                setShowModalOfferHours(false);
                setShowSkeletonsRegistrations(false);
                setLoadingRegistrations(false);
            }
        } catch (error) {
            // Error
            apiError(error);
        }
    }

    const handleChangeDate = (value) => {
        setRegisterDate(moment(value.$d).format('YYYY-MM-DD'));
        setDateLabel(moment(value.$d).format('LL'));
        getRegistrations(moment(value.$d).format('YYYY-MM-DD'), 'skeleton');
        setSubmitDate(moment(value.$d).format('YYYY-MM-DD'));
        setShowDate(moment(value.$d).format('DD-MM-YYYY'));
    }


    const deleteRow = async (id, row_id) => {
        console.log("Deleterow");
        setOpenBackdrop(true);
        //Call API
        try {
            const response = await apiCall(`registratie/${id}/verwijderen?code=${code}&rij_id=${row_id}`, 'post');

            // Success
            if (response.data.success) {
                getRegistrations(submitDate);
            }
        } catch (error) {
            // Error
            apiError(error);
        }
    }

    const renderModal = (id, row_id, date, showDate, locationId, clientId, bouwnummerId, dekId, hourTypeId, aantal_uren, opmerking) => {
        const obj = {
            id: id,
            row_id: row_id,
            date: date,
            showDate: showDate,
            location: locationId,
            client: clientId,
            buildingnumber: bouwnummerId,
            dek: dekId,
            hourtype: hourTypeId,
            opmerking: opmerking,
            aantal_uren: aantal_uren
        }
        setModalData(obj);
        setShowModal(showModal + 1);
        console.log("Show modal");
    }

    const renderOfferHoursModel = (id) => {
        const obj = {
            id: id,
            submitDate: submitDate
        }
        setModalDataOfferHours(obj);
        setShowModalOfferHours(showModalOfferHours + 1);
    }

    return(
        <div>
            <Header />
            <div className="main">
                <div className="container">
                    <div className="content">
                        <div className="heading">
                            <h1><Greeting /></h1>
                            <span className="time">
                                <Icon.Clock /><Moment format="HH:mm">{date}</Moment>
                            </span>
                        </div>
                        <div className="register">
                            <div className="column-register">
                                <RegisterTimeForm 
                                    registerDate={registerDate}
                                    changeDate={handleChangeDate}
                                    submitDate={submitDate}
                                    afgeboekt={afgeboekt}
                                    getRegistrations={getRegistrations}
                                    setOpenBackdrop={setOpenBackdrop}
                                />
                            </div>
                            
                            {/* Registered hours by day */}
                            <div className="days">
                                <div className="days-heading">
                                    <h3>Day state {dateLabel}</h3>
                                    {loadingRegistrations ?
                                        <Watch
                                            height="20"
                                            width="20"
                                            radius="48"
                                            color="white"
                                            ariaLabel="watch-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    : null}
                                </div>
                                <div className="day-rows">
                                    {showSkeletonsRegistrations ?
                                        <div className="skeletons-hours">
                                            <DayRowSkeleton />
                                            <DayRowSkeleton />
                                            <DayRowSkeleton /> 
                                        </div>
                                    :
                                        <div>
                                            {registrationObject.rijen ?
                                                registrationObject.rijen.length > 0 ?
                                                    registrationObject.rijen.map((option, index) => {
                                                        return <DayRow 
                                                        key={index}
                                                        id={registrationObject.id} 
                                                        row_id={option.id}
                                                        verlofaanvraag={option.verlofaanvraag}
                                                        showDate={showDate}
                                                        date={registrationObject.datum}
                                                        afgeboekt={afgeboekt}
                                                        aantal_uren={option.aantal_uren} 
                                                        dek={option.dekken}
                                                        bouwnummer={option.bouwnummers}
                                                        klant={option.klanten}
                                                        locatie={option.locaties}
                                                        urensoort={option.uren_soorten}
                                                        opmerking={option.opmerking}
                                                        totaal_uren={registrationObject.totaal_uren}
                                                        deleteRow={deleteRow}
                                                        renderModal={renderModal} />
                                                    })
                                                :
                                                <p className="no-registrations">No results</p>
                                            :
                                                <p className="no-registrations">No results</p>
                                            }
                                        </div>
                                    }
                                </div>
                                {totals && registrationObject && registrationObject.rijen.length > 0  ?
                                        <div className="day-actions">
                                            <div className="buttons">
                                                {afgeboekt ?
                                                    <p>Day closed</p>
                                                :
                                                    <div>
                                                        {totals !== '0.00' ?
                                                            <button className="action-button" onClick={() => renderOfferHoursModel(registrationObject.id)}>
                                                                <span>Offer hours</span>
                                                            </button>
                                                        : null}
                                                    </div>
                                                }
                                            </div>
                                            <div className="totals">
                                                <p>{totals}</p>
                                                {!afgeboekt ?
                                                    <Icon.ThreeDotsVertical />
                                                : null}
                                            </div>
                                        </div>
                                : null}
                            </div>
                            <Artwork />
                        </div>
                    </div>
                </div>
            </div>

            {/* Component bottom menu */}
            <BottomMenu />

            {/* Backdrop */}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
                <Watch
                    height="80"
                    width="80"
                    radius="48"
                    color="white"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </Backdrop>

            {/* Modal to modify registration line */}
            {showModal > 0 ?
                <ModalModify show={showModal} data={modalData} getRegistrations={getRegistrations} />
            : null}

            {/* Modal to offer hours for the day */}
            {showModalOfferHours > 0 ?
                <ModalOfferHours show={showModalOfferHours} data={modalDataOfferHours} getRegistrations={getRegistrations} submitDate={submitDate} />
            : null}
            <ToastContainer transition={Slide}/>
        </div>
    );
}

export default Home;