import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//3rd party
import * as Icon from 'react-bootstrap-icons';

//Styles
import '../css/header.css';

const Header = () => {

    const navigate = useNavigate();
    const functie_id = localStorage.getItem('functie_id');

    //Logout function
    const logout = () => {
        localStorage.removeItem("bearerToken");
        localStorage.removeItem("ingelogd");
        localStorage.removeItem("formdata");
        navigate("/login");
    }

    const current_path = window.location.pathname;

    return(
        <div className="header">
            <div className="header-container">
                <div className="logo">
                    <img src={require('../assets/images/logo.png')} alt="Gemini Teak" onClick={() => navigate("/")} />
                </div>
                <div className="nav">
                    <ul>
                        <li className={current_path == '/' ? 'cta' : 'normal'} onClick={() => navigate("/")}><Icon.ClockHistory />Day state</li>
                        {functie_id == 4 ? //Directie
                            <li className={current_path == '/overview' ? 'cta' : 'normal'} onClick={() => navigate("/overview")}><Icon.ClockHistory />Overview</li>
                        : null}
                        <li className={current_path == '/verlof' ? 'cta' : 'normal'} onClick={() => navigate("/verlof")}><Icon.Receipt />Leave of absence</li>
                        {functie_id == 3 || functie_id == 4 || functie_id == 6 ? //Voorman, Directie, voorman tekenkamer
                            <li className={current_path == '/verlof/review' ? 'cta' : 'normal'} onClick={() => navigate("/verlof/review")}><Icon.Receipt />Review leave requests</li>
                        : null}
                        {functie_id == 3 || functie_id == 4 || functie_id == 6 ? //Voorman, Directie, voorman tekenkamer
                            <li className={current_path == '/hours/review' ? 'cta' : 'normal'} onClick={() => navigate("/hours/review")}><Icon.ClockHistory />Review hours</li>
                        : null}
                        <li className="with-icon logout" onClick={logout}>Logout <Icon.BoxArrowInRight /></li>
                    </ul>
                </div>
            </div>
        </div>
        
    );
}

export default Header;