import React, { useState, useEffect, forwardRef } from 'react';

//3rd party
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import * as Icon from 'react-bootstrap-icons';

//Components
import FormRemoveRequest from './FormRemoveRequest';

//Styles
import '../css/modal.css';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalRemoveRequest = (props) => {

    const [data, setData] = useState(props.data);
    const [open, setOpen] = useState(false);

    const getLeaveRequests = props.getLeaveRequests;

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setData('');
    };

    useEffect(() => {
        if (props.show > 0) {
            setOpen(true);
            setData(props.data);
        }
    }, [props.show]);

    return (
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                id="modal-screen"
            >
                <Icon.XCircle className="close-icon" onClick={handleClose} />
                <DialogContent>
                    {/*<DialogContentText>*/}
                        {
                            data && open ?
                            <FormRemoveRequest 
                                dataToEdit={data}
                                getLeaveRequests={getLeaveRequests}
                            />
                            :
                            null
                        }
                        <p className="close-modal" onClick={handleClose}>Close</p>
                    {/*</DialogContentText>*/}
                </DialogContent>
            </Dialog>
    );

}

export default ModalRemoveRequest;