import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//Pages
import Home from './pages/Home';
import LeaveRequest from './pages/LeaveRequest';
import ReviewLeaveRequest from './pages/ReviewLeaveRequest';
import ReviewRegistrationHours from './pages/ReviewRegistrationHours';
import Login from './pages/Login';
import LostPassword from './pages/LostPassword';
import ChangePassword from './pages/ChangePassword';
import Calendar from './pages/Calendar';
import Overview from './pages/Overview';

//Settings
import Local from './settings/Local';
import Staging from './settings/Staging';
import Production from './settings/Production';

switch (window.location.hostname) {
  case "localhost":
    console.log("Local");
    global.settings = Local;
    break;
  case "www.geminiwebsite.groeier.dev":
  case "geminiwebsite.groeier.dev":
    console.log("Staging 1.0");
    global.settings = Staging;
    break;
  case "www.geminiteak-portal.vercel.app":
  case "geminiteak-portal.vercel.app":
    console.log("Production 1.0");
    global.settings = Production;
    break;
  default:
    console.log("Default omgeving");
    global.settings = Local;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/wachtwoord-vergeten" element={<LostPassword />} />
          <Route path="/wachtwoord-wijzigen" element={<ChangePassword />} />
          <Route path="/verlof" element={<LeaveRequest />} />
          <Route path="/verlof/review" element={<ReviewLeaveRequest />} />
          <Route path="/hours/review" element={<ReviewRegistrationHours />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/overview" element={<Overview />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
