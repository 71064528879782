import React from 'react';

// 3rd party components
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Dropdown = (props) => {
    const invalid = props.invalid;
    const placeholder = props.placeholder;
    const value = props.value;
    const label = props.label;
    const source = props.source;
    const handleChange = props.onChange;
    const modify = props.modify;
    const internExtern = props.internExtern || false;
    let extern = true;
    //console.log(extern);
    //console.log(internExtern);

    return (
        <FormControl sx={{ width: '100%' }} error={invalid ? true : false}>
            <InputLabel style={{ fontFamily: 'RalewayRegular' }}>{placeholder}</InputLabel>
            <Select
                value={value}
                label={label}
                onChange={handleChange}
            >
                {internExtern && (
                    <span className="intern-extern">Intern</span>
                )}
                {source.length > 0 ? (
                    source.map((option, index) => {
                        if(option.actief){
                            if(option.extern === 1 && extern && internExtern){
                                extern = false;
                                return (
                                    <div key={index}>
                                        <span className="intern-extern">Extern</span>
                                        <MenuItem value={option.value} style={{ fontFamily: 'RalewayRegular' }}>
                                            {option.label}
                                        </MenuItem>
                                    </div>
                                );
                            } else {
                                return (
                                    <MenuItem key={index} value={option.value} style={{ fontFamily: 'RalewayRegular' }}>
                                        {option.label}
                                    </MenuItem>
                                );
                            }
                        } else if(modify && !option.actief){
                            return (
                                <MenuItem key={index} value={option.value} style={{ fontFamily: 'RalewayRegular' }} disabled={modify}>
                                    {option.label}
                                </MenuItem>
                            );
                        }
                        return null;
                    })
                ) : (
                    <p className="no-options">Geen opties gevonden...</p>
                )}
            </Select>
        </FormControl>

    );
}

export default Dropdown;