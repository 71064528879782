//API
import api from '../settings/AxiosSetup';

//3rd party
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const apiCall = async (url, method = 'get', data = {}) => {
    console.log("api call met url: " + url);

    const response = await api({
        method: method,
        url: url,
        baseURL: global.settings.api.base_url,
        data: data
    });
    //console.log(response.data);

    if(!response.data.success){
        console.log(response.data.error);
        notification('error', response.data.error);
    }
    return response;
}

export const apiError = (error) => {

	//console.log(error);
    console.log(error?.response?.data?.message);
	
    // Error
    if (error.response) {
      	if (error.response.status === 401) {
            console.log("401 - unauthorized");
            notification('error', "You are unauthorized, try to login in again.");

            localStorage.removeItem("bearerToken");
            localStorage.removeItem("ingelogd");
            localStorage.removeItem("formdata");
            window.location = "/login";
        }
      	if (error.response.status === 403) {
            console.log("403 - forbidden");
            notification('error', "403: Forbidden error, contact the developer.");
        }
      	if (error.response.status === 404) {
            console.log("404 - not found");
            notification('error', "404: API not found, contact the developer.");
      	}
      	if (error.response.status === 429) {
            console.log("429 - too many request");
            notification('error', "Too many requests, wait a minut and try again please.");
        }
      	if (error.response.status === 500) {
            console.log("500 - internal server error");
            notification('error', "Internal server error, contact the developer.");
        }
    }
}

export const notification = (type, content) => {
    if (type === 'error') {
        toast.error(content, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    } else {
        toast.success(content, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
}

