import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, Slide } from 'react-toastify';

//3rd party
import Moment from 'react-moment';
import moment from 'moment';
import * as Icon from 'react-bootstrap-icons';
import { TailSpin, Watch } from  'react-loader-spinner';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Popover from '@mui/material/Popover';

//Functions
import { apiCall, apiError } from '../functions/common';

//Components
import Header from '../components/Header';
import BottomMenu from '../components/BottomMenu';
import Greeting from '../components/Greeting';
import RegisterTimeForm from '../components/RegisterTimeForm';
import DayRow from '../components/DayRow';
import DayRowSkeleton from '../components/DayRowSkeleton';
import ModalReviewHours from '../components/ModalReviewHours';
import Artwork from '../components/Artwork';
import Button from '../components/Button';

const ReviewRegistrationHours = () => {

    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const functie_id = localStorage.getItem('functie_id');
    if(functie_id != 3 && functie_id != 4 && functie_id != 6){ // Voorman, Directie, voorman tekenkamer
        navigate('/');
    }
    const code = global.settings.api.code;

    useEffect(() => {
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        setInterval(() => setDate(new Date()), 30000);
        getRegistrations(1);
    }, []);

    //Variables
    const [date, setDate] = useState(Date.now());
    const [showDate, setShowDate] = useState(moment().format('DD-MM-YYYY'));
    const [submitDate, setSubmitDate] = useState(moment().format('YYYY-MM-DD'));
    const [registerDate, setRegisterDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateLabel, setDateLabel] = useState(moment().format('LL'));
    const [registrationObject, setRegistrationObject] = useState(null);
    const [totals, setTotals] = useState('');
    const [loadingRegistrations, setLoadingRegistrations] = useState(false);
    const [showSkeletonsRegistrations, setShowSkeletonsRegistrations] = useState(true);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [afgeboekt, setAfgeboekt] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [hours, setHours] = useState(null);
    const [statuses, setStatuses] = useState(null);
    const [statusId, setStatusId] = useState(1);//Loader
    const [statusName, setStatusName] = useState('Pending');//Loader
    //let [reviewArray, setReviewArray] = useState([]);
    const [checkedIds, setCheckedIds] = useState([]);

    //Modal variables
    const [showModal, setShowModal] = useState(0);
    const [modalData, setModalData] = useState([]);

    const [showModalOfferHours, setShowModalOfferHours] = useState(0);
    const [modalDataOfferHours, setModalDataOfferHours] = useState([]);

    //Get user registrations
    const getRegistrations = async (newStatusId = 1, type = null) => {
        setLoadingRegistrations(true);
        if (type === 'skeleton') { setShowSkeletonsRegistrations(true) }
        //setTotals('');
        try {
            const response = await apiCall(`registraties/beoordelen?code=${code}&status_id=${newStatusId}`);
            // Success
            if (response.data.success) {
                const data = response.data.data;
                //console.log(data);
                setHours(data.uren);
                setStatuses(data.statussen);
                
                setOpenBackdrop(false);
                setShowModal(false);
                setShowModalOfferHours(false);
                setShowSkeletonsRegistrations(false);
                setLoadingRegistrations(false);
            }
        } catch (error) {
            // Error
            apiError(error);
        }
    }

    /*const handleChangeDate = (value) => {
        setRegisterDate(moment(value.$d).format('YYYY-MM-DD'));
        setDateLabel(moment(value.$d).format('LL'));
        getRegistrations(moment(value.$d).format('YYYY-MM-DD'), 'skeleton');
        setSubmitDate(moment(value.$d).format('YYYY-MM-DD'));
        setShowDate(moment(value.$d).format('DD-MM-YYYY'));
    }*/

    /**/
    const reviewRow = async (ids, status_id) => {
        setOpenBackdrop(true);
        //Call API
        try {
            const response = await apiCall(`registratie/beoordelen?code=${code}&uren_ids=${JSON.stringify(ids)}&status_id=${status_id}`, 'post');

            // Success
            if (response.data.success) {
                //getRegistrations(statusId);
                window.location.reload();
            }
        } catch (error) {
            // Error
            apiError(error);
        }
    }

    const renderModal = (uren_ids, status_id) => {
        const obj = {
            uren_ids: uren_ids,
            status_id: status_id
        }
        setModalData(obj);
        setShowModal(showModal + 1);
        //console.log("Show modal");
    }

    const renderOfferHoursModel = (id) => {
        const obj = {
            id: id,
            submitDate: submitDate
        }
        setModalDataOfferHours(obj);
        setShowModalOfferHours(showModalOfferHours + 1);
    }

    const changeStatus = (newStatusId, newStatusName) => {
        //Als je 2x op dezelfde drukt, dan het filter resetten
        if(newStatusId == statusId){
            newStatusId = null;
            newStatusName = null;
        }

        setStatusId(newStatusId);
        setStatusName(newStatusName);
        getRegistrations(newStatusId);
    }

    const checkboxChange = (id, checked) => {
        if(checked){
            setCheckedIds((prevIds) => [...prevIds, id]);
        } else {
            setCheckedIds((prevIds) => prevIds.filter((checkedId) => checkedId !== id));
        }
        /*
        let object = { id: id, uren_id: uren_id }
        console.log(checked);
        if(checked){
            reviewArray.push(object);
            setReviewArray(reviewArray);
        } else {
            let filteredObject = null;
            reviewArray = reviewArray.filter(e => {
                  return uren_id != e.uren_id;
            });
            setReviewArray(reviewArray);
        }
        console.log(reviewArray);*/
    }

    const sendReviews = (status_id) => {
        reviewRow(checkedIds, status_id);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return(
        <div>
            <Header />
            <div className="main">
                <div className="container">
                    <div className="content">
                        <div className="heading">
                            <h1><Greeting /></h1>
                            <span className="time">
                                <Icon.Clock /><Moment format="HH:mm">{date}</Moment>
                            </span>
                        </div>
                        <div className="register">
                            <div className="status-filter">
                                <h2>Statuses</h2>
                                {
                                    statuses ?
                                        statuses.map((option, index) => {
                                            return <Button
                                                key={index}
                                                text={option.naam}
                                                onClick={() => changeStatus(option.id, option.naam)}
                                                style={statusId != option.id ? {backgroundColor: '#ffffff', border: `1px solid ${option.kleur}`, color: option.kleur } : {backgroundColor: option.kleur, border: `1px solid ${option.kleur}`}}
                                                //active={statusId == option.id ? 1 : 0}
                                            />
                                        })
                                    :
                                    null
                                }
                            </div>
                            
                            {/* Registered hours by day */}
                            <div className="days">
                                <div className="days-heading">
                                    <h3>Review registration hours { statusName ? "- " + statusName : null}</h3>
                                    <Icon.ThreeDotsVertical aria-describedby={id} variant="contained" onClick={handleClick} />
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <span className="option approve" onClick={() => {sendReviews(2); handleClose()}}><Icon.Check size={17} />Approve</span>
                                        <span className="option reject" onClick={() => {renderModal(checkedIds, 3); handleClose()}}><Icon.Trash /> Reject</span>
                                    </Popover>
                                    {
                                        loadingRegistrations ?
                                        <Watch
                                            height="20"
                                            width="20"
                                            radius="48"
                                            color="white"
                                            ariaLabel="watch-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                        :
                                        null
                                    }
                                </div>
                                <div className="day-rows">
                                    {
                                        showSkeletonsRegistrations ?
                                            <div className="skeletons-hours">
                                                <DayRowSkeleton />
                                                <DayRowSkeleton />
                                                <DayRowSkeleton /> 
                                            </div>
                                        :
                                        <div>
                                        {
                                            hours.length > 0 ?
                                                hours.map((option, index) => {
                                                    return <DayRow 
                                                    key={index}
                                                    option={option}
                                                    id={option.registratie_id} 
                                                    row_id={option.uren_id}
                                                    showDate={showDate}
                                                    date={option.datum}
                                                    aantal_uren={option.aantal_uren} 
                                                    dek={option.dekken}
                                                    bouwnummer={option.bouwnummers}
                                                    klant={option.klanten}
                                                    locatie={option.locaties}
                                                    urensoort={option.uren_soorten}
                                                    opmerking={option.opmerking}
                                                    status_opmerking={option.status_opmerking}
                                                    reviewRow={reviewRow}
                                                    renderModal={renderModal}
                                                    medewerker={option.medewerker}
                                                    status={option.status}
                                                    checkboxChange={checkboxChange}
                                                    review={1} />
                                                })
                                            :
                                            <p className="no-registrations">No results</p>
                                        }
                                        </div>
                                    }
                                </div>
                                {
                                    totals && registrationObject && registrationObject.rijen.length > 0  ?
                                        <div className="day-actions">
                                            <div className="buttons">
                                                {
                                                    afgeboekt ?
                                                    <p>Day closed</p>
                                                    :
                                                    <div>
                                                        {
                                                            totals !== '0.00' ?
                                                            <button className="action-button" onClick={() => renderOfferHoursModel(registrationObject.id)}>
                                                                <span>Offer hours</span>
                                                            </button>
                                                            :
                                                            null
                                                        }
                                                   
                                                    </div>
                                                }
                                            </div>
                                            <div className="totals">
                                                <p>{totals}</p>
                                                {
                                                    !afgeboekt ?
                                                    <Icon.ThreeDotsVertical />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    :
                                    null
                                }
                            </div>
                            <Artwork />
                        </div>
                    </div>
                </div>
            </div>

            {/* Component bottom menu */}
            <BottomMenu />

            {/* Backdrop */}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
                <Watch
                    height="80"
                    width="80"
                    radius="48"
                    color="white"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </Backdrop>

            {/* Modal to modify registration line */}
            {
                showModal > 0 ?
                <ModalReviewHours show={showModal} data={modalData} getRegistrations={getRegistrations} />
                :
                null
            }

            <ToastContainer transition={Slide}/>
        </div>
    );
}

export default ReviewRegistrationHours;