import React, { useState } from 'react';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';

//Styles
import '../css/dayrow.css';

const DayRow = (props) => {
    const rowId = props.row_id;
    const postId = props.id;
    const option = props.option;
    console.log(option);
    const verlofaanvraag = props.verlofaanvraag;

    const showDate = props.showDate;
    const date = props.date;
    const afgeboekt = props.afgeboekt;
    const locationId = (!verlofaanvraag ? props?.locatie?.value.toString() : null);
    const locationName = (!verlofaanvraag ? props?.locatie?.label : null);
    const clientId = (!verlofaanvraag ? props?.klant?.value.toString() : null);
    const clientName = (!verlofaanvraag ? props?.klant?.label : null);
    const hourTypeId = (!verlofaanvraag ? props?.urensoort?.value.toString() : null);
    let hourType = (!verlofaanvraag ? props?.urensoort?.label : null);
    const dekId = (!verlofaanvraag ? props?.dek?.value.toString() : null);
    const dek = (!verlofaanvraag ? props?.dek?.label : null);
    const bouwnummerId = (!verlofaanvraag ? props?.bouwnummer?.value.toString() : null);
    const bouwnummer = (!verlofaanvraag ? props?.bouwnummer?.label : null);
    const aantalUren = (!verlofaanvraag ? props?.aantal_uren?.value : props.aantal_uren.value);
    const opmerking = (!verlofaanvraag ? props?.opmerking?.value : null);
    const verlofaanvraagLabel = props?.verlofaanvraag_label;
    const deleteRow = props.deleteRow;
    const renderModal = props.renderModal;
    const emptyData = props.emptyData;
    
    const review = props.review || 0;
    const medewerker = props.medewerker || null;
    const status = props.status || null;
    const reviewRow = props.reviewRow;
    const status_opmerking = props.status_opmerking;
    const checkboxChange = props.checkboxChange;
    //console.log(review);

    let hourType_complete = hourType;
    if(hourType){
        if(hourType.length > 21){
            hourType = hourType.substring(0,21) + '...';
        }
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return(
        <div>
            {
                verlofaanvraag ?
                <div className="line-item">
                    {/*
                        afgeboekt ?
                        <div className="disabled"></div>
                        :
                        null
                    */}
                    <div className="column-left">
                        <div className="item-content">
                            <p className="title"><span className="location">Leave of absence</span> - <span className="client">{verlofaanvraag.label}</span></p>
                        </div>
                    </div>
                    <div className="column-right">
                        <div className="item-type">
                            <span className="type-label" style={{backgroundColor: verlofaanvraag.status_kleur, color: 'white'}}>{verlofaanvraag.status}</span>
                        </div>
                        <div className="item-options">
                            <div className="time">
                                <span className="hours">{aantalUren}</span>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="line-item">
                    {afgeboekt ?
                        <div className="disabled"></div>
                    : null}
                    <div className="column-left">
                        <div className="item-content">
                            {review == 1 ? <p className="title"><span className="bold">{medewerker} - {date}</span></p> : null}
                            <p className="title"><span className="location">{locationName}</span> - <span className="client">({clientName})</span>{opmerking ? <Tooltip title={opmerking} arrow><Icon.ChatLeftText /></Tooltip>: null}</p>
                            <ul className="badges">
                                <li>{bouwnummer}</li>
                                <li>{dek}</li>
                                {option?.overnachting_eten && (
                                    <li>{option.overnachting_eten}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="column-right">
                        <div className="item-type">
                            {/*<span className={`status-label ${statusId == 3 ? "rejected" : ""} ${statusId == 2 ? "approved" : ""}`}>{status}</span>*/}
                            {review == 1 && status?.id ? 
                                <div className="status-absolute">
                                    <Tooltip title={status_opmerking} className="tooltip-rejected" arrow>
                                        <span className={`status-label ${status.id == 3 ? "rejected" : ""} ${status.id == 2 ? "approved" : ""} margin-left-10`}>{status.naam}</span>
                                        <Tooltip title={`${option?.aantal_beoordeeld}/${option?.aantal_beoordelen} have reviewed these registration hours`} className="tooltip-rejected" arrow><span className={`status-counter margin-left-5`}>{option.aantal_beoordeeld}/{option.aantal_beoordelen}</span></Tooltip>
                                    </Tooltip>
                                </div>
                            : null}
                            <Tooltip title={hourType_complete} className="tooltip" arrow><span className="type-label">{hourType}</span></Tooltip>
                        </div>
                        <div className="item-options">
                            <div className="time">
                                <span className="hours">{aantalUren}</span>
                            </div>
                            {!afgeboekt && review == 0 ?
                                <div className="options">
                                    <Icon.ThreeDotsVertical aria-describedby={id} variant="contained" onClick={handleClick} />
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                        }}
                                    >
                                        <span className="option delete" onClick={() => {deleteRow(postId, rowId); handleClose()}}><Icon.Trash /> Remove</span>
                                        <span className="option modify" onClick={() => {renderModal(postId, rowId, date, showDate, locationId, clientId, bouwnummerId, dekId, hourTypeId, aantalUren, opmerking); handleClose()}}><Icon.Pen /> Change</span>
                                    </Popover>
                                </div>
                            : null}

                            {review == 1 ? 
                                <div className="options options-review-hours">
                                    <Icon.ThreeDotsVertical aria-describedby={id} variant="contained" onClick={handleClick} />
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <span className="option approve" onClick={() => {reviewRow([rowId], 2); handleClose()}}><Icon.Check size={17} />Approve</span>
                                        <span className="option reject" onClick={() => {renderModal([{ id: postId, uren_id: rowId }], 3); handleClose()}}><Icon.Trash /> Reject</span>
                                    </Popover>
                                    <Checkbox onChange={(e) => checkboxChange(rowId, e.target.checked)} />
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            }

        </div>
    );
}

export default DayRow;