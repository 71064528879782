import React, { useState, useEffect } from 'react';

//3rd party
import moment from 'moment';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from  'react-loader-spinner';

//Functions
import { apiCall, apiError } from '../functions/common';

//Components
import Datepicker from './Datepicker';
import Dropdown from './Dropdown';
import DropdownGhost from './DropdownGhost';
import Button from './Button';

//Styles
import '../css/timeform.css';
import { notification } from 'antd';

const LeaveRequestForm = (props) => {

    //Props
    const dataToEdit = props.dataToEdit;
    const leaveRequestId = dataToEdit.id;
    const setOpenBackdrop = props.setOpenBackdrop;
    const getLeaveRequests = props.getLeaveRequests;

    const code = global.settings.api.code;

    //Variables
    const [data, setData] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [startdatum, setStartdatum] = useState(null);
    const [einddatum, setEinddatum] = useState(null);
    const [opmerking, setOpmerking] = useState('');
    const [confirmButton, setConfirmButton] = useState(false);
    const [confirmText, setConfirmText] = useState('');

    const today = moment();
    //const from_date = today.startOf('week');
    const minDate = today.endOf('week').add(2, 'd').format('YYYY-MM-DD');
    //const minDate = moment().format('YYYY-MM-DD');
    //console.log(minDate);

    //Functions
    const changeStartdatum = (value) => {
        setStartdatum(moment(value.$d).format('YYYY-MM-DD'));
    }

    const changeEinddatum = (value) => {
        setEinddatum(moment(value.$d).format('YYYY-MM-DD'));
    }

    //Submit registration
    const submitForm = async () => {
        //setIsUpdating(true);
        //Call API
        try {
            const response = await apiCall(`verlofaanvraag?code=${code}&startdatum=${startdatum}&einddatum=${einddatum}&opmerking=${opmerking}`, 'post');

            // Success
            if (response.data.success) {
                //getRegistrations(submitDate);
                getLeaveRequests();
            }
            setIsUpdating(false);
        } catch (error) {
            // Error
            apiError(error);
        }
    }

    const checkBeforeSubmit = async () => {
        setIsUpdating(true);
        //Call API
        try {
            const response = await apiCall(`verlofaanvragen_datum?code=${code}&startdatum=${startdatum}&einddatum=${einddatum}`);
            console.log(response);
            // Success
            if (response.data.success) {
                //getRegistrations(submitDate);
                const data = response.data.data;
                if(data.melding){
                    setConfirmButton(true);
                    setConfirmText(response.data.message);
                    setIsUpdating(false);
                } else {
                    submitForm();
                }
            } else {
                setIsUpdating(false);
            }
        } catch (error) {
            // Error
            apiError(error);
        }
    }

    return(
        <div className="default-form">
            <h2>Leave request</h2>

            {/* Date field */}
            <div className="form-field date">
                {
                    /*dataToEdit ?
                    <DropdownGhost label={dataToEdit.startdatum} />
                    :*/
                    <Datepicker date={startdatum} changeDate={changeStartdatum} label="Date from" minDate={minDate} />
                }
            </div>

            {/* Date field */}
            <div className="form-field date">
                {
                    /*dataToEdit ?
                    <DropdownGhost label={dataToEdit.einddatum} />
                    :*/
                    <Datepicker date={einddatum} changeDate={changeEinddatum} label="Date to" minDate={minDate} />
                }
            </div>

            {/* Optional notes field */}
            <div className="form-field">
                 <textarea value={opmerking} onChange={(e) => setOpmerking(e.target.value)} placeholder="Possibly for special reasons." />
            </div>

            {/* Submit form */}
            <div className="registration-submit">
                {
                    isUpdating ?
                        <TailSpin
                            height="30"
                            width="30"
                            color="#13a3f6"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            wrapperClass=""
                            visible={true}
                        />
                    :
                    <Button
                        text={"Send"}
                        onClick={() => checkBeforeSubmit()}
                    />
                }

                {
                    confirmButton ?
                        <div style={{ marginTop: 10 }}>
                            <span style={{ color: "#d11711" }}>{confirmText}</span>
                            <Button
                                text={"Yes i'm sure"}
                                style={{ backgroundColor: "#d11711", marginTop: 10 }}
                                onClick={() => submitForm()}
                            />
                        </div>
                    :
                        null
                }
                
            </div>
        </div>
    );
}

export default LeaveRequestForm;