//Api

const Staging = 
    {
        api: {
            base_url:  "https://geminibackoffice.groeier.dev/api/v1/", 
            code: "THeLS258MVapu2mYT6PW61r2W"
        }
    }


export default Staging;