import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';

//Functions
import { apiCall, apiError } from '../functions/common';

//3rd party
import { TailSpin } from  'react-loader-spinner';

//Styles
import '../css/style.css';
import '../css/login.css';

const Login = () => {

    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const code = global.settings.api.code;

    useEffect(() => {
        if (loggedIn === 'true') { navigate('/') }
    }, []);

    //Variables
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading]  = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    //Login function
    const submitLogin = async () => {
        setLoading(true);
        setErrorMessage('');
        if (!email) { setErrorMessage('E-mailadres is verplicht.'); setLoading(false); return; }
        if (!password) { setErrorMessage('Wachtwoord is verplicht.'); setLoading(false); return; }
        try {
            const data = {
                email: email,
                wachtwoord: password
            }
            const response = await apiCall(`login?code=${code}`, 'post', data);

            // Success
            if (response.data.success) {
                const data = response.data.data;
                localStorage.setItem("bearerToken", data.bearer_token);
                localStorage.setItem("ingelogd", true);
                localStorage.setItem("voornaam", data.profile.user_firstname);
                localStorage.setItem("user_id", data.profile.id);
                localStorage.setItem("functie_id", data.functie_id);
                navigate("/");
            }
        } catch (error) {
            // Error
            apiError(error);
        }
        setLoading(false);
    }

    //Hit enter function
    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            submitLogin();
        }
    };

    if (loggedIn !== 'true') {
        return(
            <div className="login">
                <div className="login-form">
                    <img src={require('../assets/images/logo.png')} alt="Gemini Teak" />
                    <div className="form">
                        <input
                            className="form-field"
                            type="email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="E-mailadres"
                        />
                        <input
                            className="form-field"
                            type="password" 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Wachtwoord"
                        />
                        

                        <button className="submit-login" onClick={submitLogin}>
                        {
                            !loading ?
                            <span>Inloggen</span>
                            :
                            <TailSpin
                            height="20"
                            width="20"
                            color="white"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            wrapperClass=""
                            visible={true}
                        />
                        }
                        </button>

                        <p className="form-link" onClick={() => navigate("/wachtwoord-vergeten")}>Wachtwoord vergeten?</p>

                        {
                            errorMessage ?
                            <p className="error">{errorMessage}</p>
                            :
                            null
                        }
                        
                        
                    </div>
                </div>
                <div className="image"></div>
                <ToastContainer transition={Slide}/>
            </div>
        );
    }
}

export default Login;