import React, { useState, useEffect } from 'react';

//3rd party
import moment from 'moment';

const Greeting = () => {

    //Variables
    const name = localStorage.getItem('voornaam');
    const [greeting, setGreeting] = useState('');

    useEffect(() => {
        const hour = parseInt(moment(Date.now()).format('H'));
        if (hour > 5 && hour < 12) { 
            setGreeting('Good morning') 
        } else if (hour > 11 && hour < 18) { 
            setGreeting('Good afternoon') 
        } else { 
            setGreeting('Good evening') 
        }
    }, []);

    return (
        <span>{greeting} {name}</span>
    );
}

export default Greeting;