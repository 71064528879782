import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

//3rd party
import * as Icon from 'react-bootstrap-icons';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';

//Styles
//import '../css/leave-request-row.css';
import '../css/dayrow.css';

const LeaveRequestRow = (props) => {
    const leaveRequestId = props.id;
    const option = props.option;
    const statusId = props.statusId;
    const status = props.status;
    const functieId = props.functieId || null;
    const beoordeelId = props.beoordeelId || null;
    const verwijderen = props.verwijderen;
    const medewerker = props.medewerker || null;
    const verlopen = props.verlopen || 0;
    const statusIdBeoordelaar = props.statusIdBeoordelaar;
    const statusBeoordelaar = props.statusBeoordelaar;
    const date = props.date;
    const startDate = props.startDate;
    const endDate = props.endDate;
    const aantalApproved = props.aantalApproved;
    const aantalFunctie = props.aantalFunctie;
    const comment = props.comment;
    const commentReview = props.commentReview;
    const uren = props.uren;
    const deleteRow = props.deleteRow;
    //const reviewLeaveRequest = props.reviewLeaveRequest;
    const renderModal = props.renderModal;
    const emptyData = props.emptyData;
    const review = props.review || 0;

    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return(
        <div className={`line-item ${review == 1 ? 'line-item-leave-request' : ''} ${verwijderen == 1 && verlopen == 0 ? 'line-item-deleted' : ''}`}>
            {verlopen == 1 ?
                <div className="disabled"></div>
            : null}
            {verwijderen == 1 && verlopen == 0 ?
                <div className="disabled-leave-request"></div>
            : null}
            <div className="column-left">
                <div className="item-content item-content-lr">
                    <div className="title title-lr">
                        <span className="employee-name">{medewerker}</span>
                        <span className="date">Application date: {date}</span>
                        <span className="date-from-to">{startDate} till {endDate}</span><br />
                        {review == 1 ? <span className="number-approved">People on leave request in this period <div className="div-link" onClick={() => navigate('/calendar', {state: {functie_id: functieId}})}>(by function): {aantalApproved}/{aantalFunctie}</div></span> : null}
                    </div>
                    {comment ? <Tooltip title={comment} arrow><Icon.ChatLeftText /></Tooltip>: null}
                </div>
            </div>
            <div className="column-right">
                <div className={`item-type`}>
                    {/*<span className={`status-label ${statusId == 3 ? "rejected" : ""} ${statusId == 2 ? "approved" : ""}`}>{status}</span>*/}
                    {statusIdBeoordelaar ? 
                        <div className="display-inline-block">
                            <Tooltip title={commentReview} className="tooltip-rejected" arrow><span className={`status-label ${statusIdBeoordelaar == 3 ? "rejected" : ""} ${statusIdBeoordelaar == 2 ? "approved" : ""} margin-left-10`}>{statusBeoordelaar}</span></Tooltip>
                        </div>
                    : 
                        <span className={`status-label ${statusId == 3 ? "rejected" : ""} ${statusId == 2 ? "approved" : ""}`}>{status}</span>
                    }
                    <Tooltip title={`${option?.aantal_beoordeeld}/${option?.aantal_beoordelen} have reviewed this leave request`} className="tooltip-rejected" arrow><span className={`status-counter margin-left-5`}>{option?.aantal_beoordeeld}/{option?.aantal_beoordelen}</span></Tooltip>
               </div>
                <div className="item-options">
                    <div className="time">
                        <span className="hours">{uren}</span>
                    </div>
                    {review == 1 || verwijderen == 0 ?
                        <div className="options">
                            <Icon.ThreeDotsVertical aria-describedby={id} variant="contained" onClick={handleClick} />
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                {review == 1 ?
                                    <div>
                                        {statusIdBeoordelaar != 2 ?
                                            <span className="option approve" onClick={() => {renderModal(leaveRequestId, 2, beoordeelId); handleClose()}}><Icon.CalendarCheck /> Approve</span>
                                        : null}
                                        {statusIdBeoordelaar != 3 ?
                                            <span className="option reject" onClick={() => {renderModal(leaveRequestId, 3, beoordeelId); handleClose()}}><Icon.CalendarX /> Reject</span>
                                        : null}
                                    </div>
                                :
                                    <span className="option delete" onClick={() => {renderModal(leaveRequestId, 0, beoordeelId); handleClose()}}><Icon.Trash /> Remove</span>                                    
                                }
                                {/*<span className="option modify" onClick={() => {renderModal(postId, rowId, date, locationId, clientId, bouwnummerId, dekId, hourTypeId, overnachting_eten, aantal_uren, opmerking); handleClose()}}><Icon.Pen /> Change</span>*/}
                            </Popover>
                        </div>
                    : null}
                </div>
            </div>
        </div>
    );
}

export default LeaveRequestRow;