import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from 'react-toastify';

//3rd party
import 'react-loading-skeleton/dist/skeleton.css';
import { Watch } from  'react-loader-spinner';
import Backdrop from '@mui/material/Backdrop';

//Functions
import { apiCall, apiError } from '../functions/common';

//Components
import Header from '../components/Header';
import BottomMenu from '../components/BottomMenu';
import LeaveRequestRow from '../components/LeaveRequestRow';
import DayRowSkeleton from '../components/DayRowSkeleton';
import Button from '../components/Button';
import ModalReviewRequest from '../components/ModalReviewRequest';

//Styles
import '../css/leave-request.css';

const ReviewLeaveRequest = () => {

    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const functie_id = localStorage.getItem('functie_id');
    if(functie_id != 3 && functie_id != 4 && functie_id != 6){ // Voorman, Directie, voorman tekenkamer
        navigate('/');
    }
    const code = global.settings.api.code;

    //Variables
    const [showSkeletonLeaveRequest, setShowSkeletonLeaveRequest] = useState(true);
    const [loadingLeaveRequests, setLoadingLeaveRequests] = useState(false);
    const [leaveRequests, setLeaveRequests] = useState(false);
    const [statuses, setStatuses] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);//Loader
    const [statusId, setStatusId] = useState(null);//Loader
    const [statusName, setStatusName] = useState(null);//Loader
    const [showModal, setShowModal] = useState(0);//Loader
    const [modalData, setModalData] = useState([]);//Loader

    useEffect(() => {
        if (loggedIn !== 'true') { navigate('/login') };

        getLeaveRequests();
    }, []);

    const renderModal = (id, statusId, beoordeelId) => {
        console.log(statusId);
        const obj = {
            id: id,
            status_id: statusId,
            beoordeel_id: beoordeelId
        }
        setModalData(obj);
        setShowModal(showModal + 1);
    }

    //Leave requests
    const getLeaveRequests = async (newStatusId = null, type = null) => {
        setLoadingLeaveRequests(true);
        if (type === 'skeleton') { setShowSkeletonLeaveRequest(true) }

        try {
            console.log("ophalen met status " + newStatusId);
            const response = await apiCall(`verlofaanvragen/beoordelen?code=${code}&status_id=${newStatusId}`);
            // Success
            if (response.data.success) {
                const data = response.data.data;
                setLeaveRequests(data.verlofaanvragen);
                setStatuses(data.verlofaanvraag_statussen);
                /*
                setOpenBackdrop(false);*/
                setShowModal(0);
                setShowSkeletonLeaveRequest(false);
                setLoadingLeaveRequests(false);
            }
        } catch (error) {
            // Error
            apiError(error);
        }
    }

    const changeStatus = (newStatusId, newStatusName) => {
        //Als je 2x op dezelfde drukt, dan het filter resetten
        if(newStatusId == statusId){
            newStatusId = null;
            newStatusName = null;
        }

        setStatusId(newStatusId);
        setStatusName(newStatusName);
        getLeaveRequests(newStatusId);
    }

    /*
    const reviewLeaveRequest = async (id, statusId, opmerking = null) => {
        setOpenBackdrop(true);
        //Call API
        try {
            const response = await apiCall(`verlofaanvraag/${id}/beoordelen?code=${code}&status_id=${statusId}&opmerking=${opmerking}`, 'post');

            // Success
            if (response.data.success) {
                getLeaveRequests();
            }
            setOpenBackdrop(false);
        } catch (error) {
            // Error
            apiError(error);
        }
    }*/

    return(
        <div>
            <Header />
            <div className="main">
                <div className="container">
                    <div className="content">
                        <div className="register">
                            <div className="status-filter">
                                <h2>Statuses</h2>
                                {
                                    statuses ?
                                        statuses.map((option, index) => {
                                            return <Button
                                                key={index}
                                                text={option.naam}
                                                onClick={() => changeStatus(option.id, option.naam)}
                                                style={statusId != option.id ? {backgroundColor: '#ffffff', border: `1px solid ${option.kleur}`, color: option.kleur } : {backgroundColor: option.kleur, border: `1px solid ${option.kleur}`}}
                                                //active={statusId == option.id ? 1 : 0}
                                            />
                                        })
                                    :
                                    null
                                }
                            </div>
                            <div className="days-leave-request">
                                <div className="days-heading">
                                    <h3>Review leave requests { statusName ? "- " + statusName : null}</h3>
                                    {
                                        loadingLeaveRequests ?
                                        <Watch
                                            height="20"
                                            width="20"
                                            radius="48"
                                            color="white"
                                            ariaLabel="watch-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                        :
                                        null
                                    }
                                </div>
                                <div className="day-rows">
                                    {
                                        showSkeletonLeaveRequest ?
                                            <div className="skeletons-hours">
                                                <DayRowSkeleton />
                                                <DayRowSkeleton />
                                                <DayRowSkeleton /> 
                                            </div>
                                        :
                                        <div>
                                        {
                                            leaveRequests ?
                                                leaveRequests.map((option, index) => {
                                                    return <LeaveRequestRow 
                                                        key={index}
                                                        id={option.id}
                                                        option={option}
                                                        statusId={option.status_id}
                                                        status={option.status}
                                                        functieId={option.functie_id}
                                                        beoordeelId={option.vab_id}
                                                        verwijderen={option.verwijderen}
                                                        medewerker={option.medewerker}
                                                        verlopen={option.verlopen}
                                                        statusIdBeoordelaar={option.status_id_beoordeling_medewerker}
                                                        statusBeoordelaar={option.status_beoordeling_medewerker}
                                                        date={option.datum_aanvraag}
                                                        startDate={option.startdatum}
                                                        endDate={option.einddatum}
                                                        aantalApproved={option.aantal_approved}
                                                        aantalFunctie={option.aantal_functie}
                                                        comment={option.opmerking}
                                                        commentReview={option.opmerking_beoordeling}
                                                        uren={option.uren}
                                                        //reviewLeaveRequest={reviewLeaveRequest}
                                                        renderModal={renderModal} 
                                                        review={1}
                                                        />
                                                    })
                                            :
                                            <p className="no-registrations">No results</p>
                                        }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BottomMenu />

            {/* Backdrop */}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
                <Watch
                    height="80"
                    width="80"
                    radius="48"
                    color="white"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </Backdrop>

            {/* Modal to modify deny leave request */}
            {
                showModal > 0 ?
                <ModalReviewRequest show={showModal} data={modalData} getLeaveRequests={getLeaveRequests} />
                :
                null
            }
            <ToastContainer transition={Slide}/>
       </div>
    );
}

export default ReviewLeaveRequest;