import React from 'react';

//3rd party
import { TailSpin } from  'react-loader-spinner';

//Styles
import '../css/style.css';

const Button = (props) => {
    //Props
    const text = props.text;
    const onClick = props.onClick;
    const style = props.style;
    const active = props.active;

    //Variables
    const loading = false;

    return(
        <button className={`button ${active ? "active" : ""}`} onClick={onClick} style={style}>
        {
            !loading ?
            <span>{text}</span>
            :
            <TailSpin
            height="20"
            width="20"
            color="white"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{
                alignItems: 'center',
                justifyContent: 'center'
            }}
            wrapperClass=""
            visible={true}
        />
        }
        </button>
    );
}

export default Button;