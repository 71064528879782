import React, { useState } from 'react';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';

//Styles
import '../css/dayrow.css';

const DayRowOverview = (props) => {
    // Props
    const option = props.option;
    const registration = props.registration;
    const showDate = props.showDate;
    const deleteRow = props.deleteRow;
    const renderModal = props.renderModal;
    const isChecked = props.isChecked;
    const onCheckboxChange = props.onCheckboxChange;

    // Data
    const row_id = option.id;
    const verlofaanvraag = option.verlofaanvraag;
    const uren_status = option.uren_status;
    const review_status = option.review_status;
    const hours = (!verlofaanvraag ? option?.aantal_uren?.value : option.aantal_uren.value);
    const deck_id = (!verlofaanvraag ? option?.dekken?.value.toString() : null);
    const deck_name = (!verlofaanvraag ? option?.dekken?.label : null);
    const construction_number_id = (!verlofaanvraag ? option?.bouwnummer?.value.toString() : null);
    const construction_number_name = (!verlofaanvraag ? option?.bouwnummers?.label : null);
    const client_id = (!verlofaanvraag ? option?.klanten?.value.toString() : null);
    const client_name = (!verlofaanvraag ? option?.klanten?.label : null);
    const location_id = (!verlofaanvraag ? option?.locaties?.value.toString() : null);
    const location_name = (!verlofaanvraag ? option?.locaties?.label : null);
    const hour_type_id = (!verlofaanvraag ? option?.uren_soorten?.value.toString() : null);
    const hour_type = (!verlofaanvraag ? option?.uren_soorten?.label : null);
    const notes = (!verlofaanvraag ? option?.opmerking?.value : null);
    const registration_id = registration.id;
    const date = registration.datum;
    const date_nl = registration.datum_nl;
    const afgeboekt = registration.afgeboekt;
    const employee = registration.medewerker;
    const review = 0;
    const status_opmerking = 0;
    const reviewRow = 0;
    const checkboxChange = 0;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return(
        <tr className={`${afgeboekt == 1 ? 'afgeboekt' : ''}`}>
            <td>
                {review_status && afgeboekt == 1  ?
                    <input type='checkbox' checked={isChecked} onChange={onCheckboxChange} />
                : null}
            </td>
            <td>{date_nl}</td>
            <td>{employee}</td>
            <td>{location_name}</td>
            <td>{client_name}</td>
            <td>{construction_number_name}</td>
            <td>{deck_name}</td>
            <td>{hour_type}</td>
            <td>
                {notes ? 
                    <Tooltip title={notes}>
                        <Icon.ChatLeftText />
                    </Tooltip>
                : null}
            </td>
            <td>{hours}</td>
            <td>
                {uren_status ?
                    <div className='uren-status'>
                        <span className={`status-label-overview ${uren_status.id == 3 ? "rejected" : ""} ${uren_status.id == 2 ? "approved" : ""}`}>{uren_status.naam}</span>
                        {!review_status || afgeboekt == 0 ? 
                            <Tooltip title={`${option?.aantal_beoordeeld}/${option?.aantal_beoordelen} have reviewed these registration hours`} arrow>
                                <span className={`status-counter-overview`}>{option?.aantal_beoordeeld}/{option?.aantal_beoordelen}</span>
                            </Tooltip>
                        : null}
                    </div>
                : null}
            </td>
            <td>
                {review_status && afgeboekt == 1 ?
                    <div className='uren-status'>
                        <span className={`status-label-overview ${review_status.id == 3 ? "rejected" : ""} ${review_status.id == 2 ? "approved" : ""}`}>{review_status.naam}</span>
                        <Tooltip title={`${option?.aantal_beoordeeld}/${option?.aantal_beoordelen} have reviewed these registration hours`} arrow>
                            <span className={`status-counter-overview`}>{option?.aantal_beoordeeld}/{option?.aantal_beoordelen}</span>
                        </Tooltip>
                    </div>
                : null}
            </td>
        </tr>
    );
}

export default DayRowOverview;