import React from 'react';
import { useNavigate } from "react-router-dom";

//3rd party
import * as Icon from 'react-bootstrap-icons';

//Styles
import '../css/bottom-menu.css';

const BottomMenu = () => {
    const navigate = useNavigate();
    const functie_id = localStorage.getItem('functie_id');

    //Logout function
    const logout = () => {
        localStorage.removeItem("bearerToken");
        localStorage.removeItem("ingelogd");
        localStorage.removeItem("formdata");
        navigate("/login");
    }

    return(
        <div className="bottom-nav">
            <div className="nav-item" onClick={() => navigate("/")}>
                <Icon.ClockHistory />
                <p>Day state</p>
            </div>
            <div className="nav-item" onClick={() => navigate("/verlof")}>
                <Icon.Receipt />
                <p>Leave of absence</p>
            </div>
            { functie_id == 3 || functie_id == 4 || functie_id == 6 ? //Voorman, Directie, voorman tekenkamer
                <div className="nav-item" onClick={() => navigate("/verlof/review")}>
                    <Icon.Receipt />
                    <p>Review leave requests</p>
                </div>
                : null
            }
            { functie_id == 3 || functie_id == 4 || functie_id == 6 ? //Voorman, Directie, voorman tekenkamer
                <div className="nav-item" onClick={() => navigate("/hours/review")}>
                    <Icon.ClockHistory />
                    <p>Review hours</p>
                </div>
                : null
            }
            <div className="nav-item" onClick={logout}>
                <Icon.BoxArrowInRight />
                <p>Logout</p>
            </div>
        </div>
        
    );
}

export default BottomMenu;