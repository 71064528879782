import React from 'react';

//3rd party
import * as Icon from 'react-bootstrap-icons';

//Styles
import '../css/dropdown.css';

const DropdownGhost = (props) => {

    const invalid = props.invalid;
    const label = props.label;

    return (
        <div className={invalid ? 'dropdown-ghost invalid' : 'dropdown-ghost'}>
            <p>{label}</p>
            <Icon.CaretDownFill />
        </div>
    );
}

export default DropdownGhost;