import React, { useState } from 'react';

//3rd party
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
//import 'dayjs/locale/nl'; // Importeer de Nederlandse locale

//Styles
import '../css/datepicker.css';

const Datepicker = (props) => {

    const changeDate = props.changeDate;
    const label = props.label || "Date";
    const minDate = props.minDate ? dayjs(props.minDate) : undefined;

    const [value, setValue] = useState(dayjs(props.date));

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label}
          value={value}
          minDate={minDate}
          onChange={(newValue) => {
            setValue(newValue);
            changeDate(newValue);
          }}
          inputFormat="DD/MM/YYYY"
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    );
}

export default Datepicker;