import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';

//Functions
import { apiCall, apiError } from '../functions/common';

//3rd party
import { TailSpin } from  'react-loader-spinner';

//Styles
import '../css/style.css';
import '../css/login.css';

const ChangePassword = () => {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");
    const loggedIn = localStorage.getItem('ingelogd');
    const code = global.settings.api.code;

    useEffect(() => {
        if (loggedIn === 'true') { navigate('/') }
        if (!token) {
            navigate('/login');
        }
    }, []);
    
    //Variables
    const [password, setPassword] = useState('');
    const [passwordRepeated, setPasswordRepeated] = useState('');
    const [loading, setLoading]  = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    //Lost password function
    const postChangePassword = async (token) => {
        setLoading(true);
        //setErrorMessage('');
        try {
            const response = await apiCall(`wachtwoord/wijzigen?code=${code}&token=${token}&wachtwoord=${password}&wachtwoord_herhalen=${passwordRepeated}`, 'post');
            // Success
            if (response.data.success) {
              setSuccessMessage(response.data.message);
            }
        } catch (error) {
            // Error
            apiError(error);
        }
        setLoading(false);
    }

    if (loggedIn !== 'true') {
        return(
            <div className="login">
                <div className="login-form">
                    <img src={require('../assets/images/logo.png')} alt="Gemini Teak" />
                    <div className="form">

                        {
                            !successMessage ?
                            <div>
                                <input
                                    className="form-field"
                                    type="password" 
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Nieuw wachtwoord"
                                />
                                <input
                                    className="form-field"
                                    type="password" 
                                    value={passwordRepeated}
                                    onChange={(e) => setPasswordRepeated(e.target.value)}
                                    placeholder="Nieuw wachtwoord herhalen"
                                />
                            </div>
                            :
                            <p className="success">{successMessage}</p>
                        }
                        
                        
                        {
                            !successMessage ?
                            <button className="submit-login" onClick={() => postChangePassword(token)}>
                            {
                                !loading ?
                                <span>Opslaan</span>
                                :
                                <TailSpin
                                height="20"
                                width="20"
                                color="white"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                wrapperClass=""
                                visible={true}
                            />
                            }
                            </button>
                            :
                            null
                        }
                        

                        <p className="form-link" onClick={() => navigate("/login")}>Terug naar inloggen</p>

                        {
                            errorMessage ?
                            <p className="error">{errorMessage}</p>
                            :
                            null
                        }
                        
                        
                    </div>
                </div>
                <div className="image"></div>
                <ToastContainer transition={Slide}/>
            </div>
        );
    }
}

export default ChangePassword;